import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

// Lazy load the Thankyou component
const Thankyou = loadable(() => import("../components/ThankyouPage"))

export default function ThankyouCareersPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/thank-you" />
      </Helmet>
      <Thankyou
        description={
          "We appreciate your interest in joining our team. Our HR representative will be in touch with you shortly to discuss your qualifications, experience, and career goals."
        }
      />
    </Layout>
  )
}
